import { startCase } from 'lodash'
import { CheckCircleIcon, ChevronDownIcon, Copy } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import { TableColumn } from '@/models'
import { Ratings } from '@/models/FeedbackType'
import { GenAIFeedbackElement } from '@/models/GenAIFeedback'
import { unsensitiveFilter } from '@/utils'

export const GenAIFeedbackTableColumns: TableColumn<GenAIFeedbackElement>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <div className="flex items-center space-x-2">
        <Checkbox
          aria-label="Select all in the page"
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="cursor-pointer">
              <ChevronDownIcon className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 flex items-center">
            <Checkbox
              aria-label="Select all"
              checked={table.getIsAllRowsSelected()}
              className="mr-2"
              id="select-all"
              onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
            />
            <span>Select all items</span>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ),
    cell: ({ row }) => (
      <Checkbox
        aria-label="Select row"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
      />
    ),
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 40,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'org_id',
    accessorKey: 'org_id',
    displayName: 'Org ID',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Org ID" />
    ),
    enableResizing: false,
    size: 60,
    filterFn: unsensitiveFilter
  },
  {
    id: 'user_id',
    accessorKey: 'user_id',
    displayName: 'User ID',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="User ID" />
    ),
    enableResizing: false,
    size: 60,
    filterFn: unsensitiveFilter
  },
  {
    id: 'org_name',
    accessorKey: 'org_name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Org Name" />
    ),
    displayName: 'Org Name',
    size: 300,
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'job_type',
    accessorKey: 'job_type',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Job Type" />
    ),
    cell: ({ row }) => {
      return startCase(row.getValue('job_type'))
    },
    displayName: 'Job Type',
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'rating',
    accessorKey: 'rating',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Rating" />
    ),
    cell: ({ row }) => {
      const rating = Ratings.find(
        (rating) =>
          rating.value.toLowerCase() ===
          (row.getValue('rating') as string).toLowerCase()
      )

      if (!rating) {
        return null
      }

      return (
        <div className="flex w-[100px] items-center">
          {rating.icon && (
            <rating.icon className="w-4 h-4 mr-2 text-muted-foreground" />
          )}
          <span>{rating.label}</span>
        </div>
      )
    },
    displayName: 'Feedback Type',
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'feedback',
    accessorKey: 'feedback',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Feedback" />
    ),
    cell: ({ row }) => (
      <div className="relative">
        <Textarea id="feedback" value={row.getValue('feedback') as string} />
        <Button
          className="absolute right-1 bottom-1 p-0 m-0"
          onClick={() => {
            navigator.clipboard.writeText(row.getValue('feedback') as string)
            toast({
              title: (
                <div className="flex items-center">
                  <CheckCircleIcon className="w-4 h-4 mr-2" />
                  Feedback copied to clipboard
                </div>
              ) as any,
              duration: 1000
            })
          }}
          variant="link"
        >
          <Copy className="h-3 w-3" />
        </Button>
      </div>
    ),
    displayName: 'Feedback',
    size: 300,
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'id', // because of key issue named as id
    accessorKey: 'job_id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Job ID" />
    ),
    displayName: 'Job ID',
    size: 300,
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Creation Date" />
    ),
    accessorFn: (row) => (row.created_at ? row.created_at : undefined),
    cell: ({ row }) => {
      const date = new Date(row?.original?.created_at as string)

      return date.toLocaleString()
    },
    sortingFn: (a, b) => {
      const dateA = new Date(a?.original?.created_at as string)
      const dateB = new Date(b?.original?.created_at as string)

      if (dateA === null && dateB === null) {
        return 0
      } else if (dateA === null) {
        return 1
      } else if (dateB === null) {
        return -1
      }

      return dateA.getTime() - dateB.getTime()
    },
    displayName: 'Creation Date',
    sortUndefined: -1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'last_updated_at',
    accessorKey: 'last_updated_at',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Updated Date" />
    ),
    accessorFn: (row) =>
      row?.last_updated_at ? row.last_updated_at : undefined,
    cell: ({ row }) => {
      const date = new Date(row?.original.last_updated_at as string)

      return date.toLocaleString()
    },
    sortingFn: (a, b) => {
      const dateA = new Date(a.original?.last_updated_at as string)
      const dateB = new Date(b.original?.last_updated_at as string)

      if (dateA === null && dateB === null) {
        return 0
      } else if (dateA === null) {
        return 1
      } else if (dateB === null) {
        return -1
      }

      return dateA.getTime() - dateB.getTime()
    },
    displayName: 'Updated Date',
    sortUndefined: -1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return <DataTableRowActions row={row} />
    },
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 30
  }
]
